<template lang="html">

  <div v-for="frm in formulariReverse" v-bind:key="frm" class="formular">
    <div v-for="(text,i) in frm.formular"  v-bind:key="i">
      <h3 v-if="i==0">{{frm.formular[i]}}</h3>
      <p v-if="i!=0">{{frm.formular[i]}}</p>
    </div>
    <button type="button" name="button" @click="removeFormular(frm._id)">Ukloni Formular</button>
  </div>



</template>

<script>
// eslint-disable-next-line no-unused-vars
import {mapActions,mapGetters} from 'vuex';
export default {
  data(){
    return{
      formulariReverse:[],
    }
  },
  computed: mapGetters(["formulari"]),
  methods:{
  ...mapActions(['getAllFormulars','deleteServiceApplication']),
  async removeFormular(formularID){
    await this.deleteServiceApplication(formularID);
    await this.getAllFormulars();
    await this.reverseFormulari();
  },
  reverseFormulari(){
    this.formulariReverse=[];
    let duzina = this.formulari.length;
    for (var i = duzina-1; i >= 0; i--) {
      this.formulariReverse.push(this.formulari[i]);
    }
  }
},
async created(){
  await this.getAllFormulars();
  this.reverseFormulari();
}
}
</script>

<style lang="css" scoped>
.formular{
  background-color: white;
  color: black;
  border-style: solid;
  border-color: black;
  border-width: medium;
  padding:10px;
  margin-bottom: 5px;
}
</style>
